import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
/* import BtnDarkMode from '../btnDarkMode/BtnDarkMode'; */
import logo from './../../img/logo.png';
import './style.css';


const Navbar = () => {
  const [active, setActive] = useState('home');
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    setActive(currentPath || 'home');
  }, [location]);

  const handleSetActive = (to) => {
    setActive(to);
  };
  
  const hideLinks = window.location.pathname.startsWith('/service/') || window.location.pathname === '/services';


  return (
    
    
    <nav className="nav">
      <div className="container">
        <div className="nav-row">
          <div className="nav-row--logo">
            {!hideLinks && (
                <>
                <Link to="main"
                spy={true}
                smooth={true}
                offset={-30}
                duration={500}
                onSetActive={handleSetActive} className="logo__image">
                <img width={100} height={100} src={logo} alt="" />
                </Link>
                </>
            )}
            {hideLinks && (
              <>
              <NavLink to="/"
                onSetActive={handleSetActive} className="logo__image">
                <img width={100} height={100} src={logo} alt="" />
              </NavLink>
              </>
            )}
            
            <div className="logo__name">ООО «МенеджментСтройКонтроль»</div>
          </div>
          <div className='contact'>
          <div className='contact__phone'>+ 7 ( 906 ) 111-22-72<br/>
          <span className='contact__email'>msk-kazan@inbox.ru</span></div>
          </div>
          {/* <BtnDarkMode /> */}

          <ul className="nav-list">
            {!hideLinks && (
              <>
                <li className="nav-list__item">
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    onSetActive={handleSetActive}
                    activeClass={
                      active === 'home' ? 'nav-list__link--active' : ''
                    }
                  >
                    О компании
                  </Link>
                </li>

                <li className="nav-list__item">
                  <Link
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-79}
                    duration={500}
                    onSetActive={handleSetActive}
                    activeClass={
                      active === 'services' ? 'nav-list__link--active' : ''
                    }
                  >
                    Услуги
                  </Link>
                </li>

                <li className="nav-list__item">
                  <Link
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                    onSetActive={handleSetActive}
                    activeClass={
                      active === 'contacts' ? 'nav-list__link--active' : ''
                    }
                  >
                    Контакты
                  </Link>
                </li>
              </>
            )}

            {hideLinks && (
              <>
                
                <li className="nav-list__item">
                  <NavLink
                    exact='true'
                    to="/"
                    className="nav-list__link"
                    activeclassname="nav-list__link--active"
                  >
                    На главную
                  </NavLink>
                </li>

                <li className="nav-list__item">
                  <NavLink
                    exact='true'
                    to="/services"
                    className="nav-list__link"
                    activeclassname="nav-list__link--active"
                  >
                    Услуги
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
    
  );
  
};

export default Navbar;

