import Ymap from '../components/ymap/Ymap';

const Contacts = () =>{
    return(
        <>
        <main className="section" id='contacts'>
            <div className='container'>
            <h1 className="title-1">Контакты</h1>
            <div className="cont">
                    <div className='left'>
                    <ul className="content-list">
                        <li className="content-list__item">
                            <h2 className="title-2">Телефон</h2>
                            <p><a href="tel:+79061112272">+7 (906) 111-22-72</a></p>
                        </li>
                        <li className="content-list__item">
                            <h2 className="title-2">Адрес электронной почты</h2>
                            <p><a href="msk-kazan@inbox.ru">msk-kazan@inbox.ru</a></p>
                        </li>
                        <li className="content-list__item">
                            <h2 className="title-2">Адрес</h2>
                            <p>г Казань, ул. Чистопольская, д. 9Б, офис 3-5</p>
                        </li>
                    </ul>
                    </div>
                    <div className='right'>
                    <Ymap/>   
                    </div>
            </div>
            </div>
        </main>
        </>

    );
    
}



export default Contacts;