import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import "./style.css"


const Ymap =()=>{
    return(
    <YMaps>
        <Map className='mapa'
        
          defaultState={{
            center: [55.820303, 49.106639],
            zoom: 13,
            controls: ["zoomControl", "fullscreenControl"],
            
          }}
          modules={["control.ZoomControl", "control.FullscreenControl"]}
        >
          <Placemark
            modules={["geoObject.addon.balloon"]}
            defaultGeometry={[55.820303, 49.106639]}
            properties={{
              balloonContentBody:
                "ООО «МенеджментСтройКонтроль»",
            }}
          />
        </Map>
    </YMaps>
    );
}

export default Ymap;
