import { NavLink } from 'react-router-dom';
import {Link} from 'react-scroll';
import "./style.css"

const Footer = () =>{
   

    return(
        <footer className="footer">
                <div className="container">
                    <div className="footer__wrapper">
                        
                        <div className="copyright">
                            <p>© 2023 msk-tat.ru</p>
                        </div>

                        <div className="aboutus">
                            <h2>О КОМПАНИИ</h2>
                            <Link
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-30}
                                duration={500}>
                                <p>О нас</p>
                            </Link>
                            <Link
                                to="objects"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={500}>
                                <p>Объекты</p>
                            </Link>
                        </div>

                        <div className='services'>
                            <h2>УСЛУГИ</h2>
                            <NavLink to='/service/0'>
                                <p>Строительно монтажные работы</p>
                            </NavLink>
                            <NavLink to='/service/1'>
                                <p>Разработка проектной документации</p>
                            </NavLink>
                            <NavLink to='/service/2'>
                                <p>Геодезические услуги</p>
                            </NavLink>
                            <NavLink to='/service/3'>
                                <p>Ремонт и зачистка РВС и РГС</p>
                            </NavLink>
                            <NavLink to='/service/4'>
                                <p>Обследование зданий и сооружений</p>
                            </NavLink>
                            <NavLink to='/service/5'>
                                <p>Строительный контроль</p>
                            </NavLink>
                        </div>

                        <div className='contacts'>
                            <h2>КОНТАКТЫ</h2>
                            <p>+7 ( 906 ) 111-22-72</p>
                            <p>msk-kazan@inbox.ru</p>
                        </div>
                    </div>
                </div>
            </footer>
    );
}


export default Footer; 