
import Project from "../components/project/Project";

import {objects} from "../helpers/objectsList";

const Projects = () => {
    
    return(
        <main className="photo" id="services">
            <div className="section">
            <div className="container">
            <h1 className="title-1">Наши услуги</h1>
            </div>
            <div className="photo__a">
                <ul className="photo__img">
                    {objects.map((object, index) => {
                        return <Project 
                            key={index} 
                            title={object.title}
                            img={object.img} 
                            index={index}
                             />;                        
                    })}
                </ul>
            </div>
        </div>
        </main>
    );
}


export default Projects;