import "./style.css";




const Header = () =>{
    return(
        
        <header className="header" id="main">
                <div className="header__wrapper">
                    <h1 className="header__title">
                        <strong>МенеджментСтройКонтроль</strong><br/>
                        Оказывает услуги по проектированию и строительству, обследованию, ремонту и эксплуатации объектов капитального строительства.
                    </h1>
                </div>
        </header>
    );
}


export default Header;