import "./style.css"
import { NavLink } from "react-router-dom"; 


const Project = ({title,img,index}) => {
   
    return(
        <div className="project">
            <img src={img} alt={title} className="project__img"/>
            <h3 className="project__title">{title}</h3> 
            <div className="project__button">
                <NavLink to={`/service/${index}`}>
                <button className="project__btn">Подробнее</button>
                </NavLink>
            </div>
        </div>
    );
}

export default Project;