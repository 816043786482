import {useParams} from 'react-router-dom';
import {objects} from "./../helpers/objectsList"





const Project = () =>{
    const {id} = useParams();
    const object = objects[id];
    
    return(
<main className="service-details-section">
  <div className="service-details-container">
    <div className="service-details">
      <div className="service-details__cover">
        <h1>{object.title}</h1>      
        <img src={object.imgBig} alt={object.title} />
      </div>

      

      <div className="service-details__desc">
        <p>{object.description}</p>
      </div>

      <ul className="service-details__pull">
        {object.p1 && <li>{object.p1}</li>}
        {object.p2 && <li>{object.p2}</li>}
        {object.p3 && <li>{object.p3}</li>}
        {object.p4 && <li>{object.p4}</li>}
        {object.p5 && <li>{object.p5}</li>}
        {object.p6 && <li>{object.p6}</li>}
        {object.p7 && <li>{object.p7}</li>}
        {object.p8 && <li>{object.p8}</li>}
      </ul>
      
    </div>
  </div>
</main>



    


      
    );
}

export default Project;