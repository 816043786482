import Header from './../components/header/Header'
import Projects from './Projects'
import Contacts from './Contacts'
import About from './About'
import obj1 from './../img/projects/01.jpg'
import obj2 from './../img/projects/02.jpg'
import obj3 from './../img/projects/03.jpg'
import obj4 from './../img/projects/04.jpg'

const Home = () => {
    return(
        <>
        <Header/>
        <About/>
        <Projects/>
        <main className="pages">
            <div className='section'>
            <div className='container' id='objects'>
            <div className='cont'>
                <div className='left'>
                    
                </div>
                <div className='right'>
                    <div className='sectiontt'>
                    <h2 className='title-3'>
                    Наша компания ООО «МенеджсентСтройКонтроль» готова предложить широкий спектр услуг по проведению на территории РФ различных видов услуг. Мы заинтересованы в росте наших надежных заказчиков, и в свою очередь предлагаем Вам оказание гарантированных услуг, надежность и порядочность.
                    </h2>
                    </div>
                </div>
            </div>
            <h1 className="title-1">Наши объекты</h1>
            </div>
                <div className='ocard-section'>
                    <div className='ocard'>
                        <img src = {obj1} alt=''/>
                        <h3>АО «Казанский вертолетный завод»</h3>
                    </div>
                    <div className='ocard'>
                        <img src = {obj2} alt=''/>
                        <h3>ООО «МетроКонТ»</h3>
                    </div>
                    <div className='ocard'>
                        <img src = {obj3} alt=''/>
                        <h3>МБОУ и МБДОУ Бавлинский муниципальный район</h3>
                    </div>
                    <div className='ocard'>
                        <img src = {obj4} alt=''/>
                        <h3>КАЗ им. С.П Горбунова - филиал ПАО "Туполев"</h3>
                    </div>
                </div>
            </div>
        </main>
        <Contacts/>
        </>
    );
}


export default Home;