
import object01Big from "./../img/services/s01-big.png"

import object02Big from "./../img/services/s02-big.png"

import object03Big from "./../img/services/s03-big.png"

import object04Big from "./../img/services/s04-big.png"

import object05Big from "./../img/services/s05-big.png"

import object06Big from "./../img/services/s06-big.png"



const objects = [
    {
        title: 'Строительно-монтажные работы',
        description: 
        'ООО "МСК" предлагает широкий спектр услуг в сфере строительно-монтанжных работ:',
        img: object01Big,
        imgBig: object01Big,
        p1:'• техническое перевооружение, реконструкция и капитальное строительство;',
        p2:'• проектирование и строительство производственных зданий и сооружений;',
        p3:'• электромонтажные работы;',
        p4:'• автоматизация;',
        p5:'• установка и обслуживание кондиционеров и сплит-систем;',
        p6:'• пуско-наладочные работы;',
        p7:'• комплексное опробование оборудования перед вводом в эксплуатацию. '
    },

    {
        title: 'Разработка проектной документации',
        description: 'ООО «МСК» предлагает комплекс услуг по разработке проектной и рабочей документации  высококвалифицированными специалистами. Наши специалисты помимо опыта в сфере проектных работ имеют большой опыт в сфере строительства и строительного контроля, что в свою очередь помогает достичь максимальных результатов в максимально сжатые сроки.',
        img: object02Big,
        imgBig: object02Big,
        p1:'• предпроектное обследование (предварительное обследование объекта, инженерно-геологическое изыскание, помощь в разработке технического задания на разработку проектной документации);',
        p2:'• разработка проектной документации;',
        p3:'• разработка рабочей документации.'
    },

    {
        title: 'Геодезические услуги',
        description: 'ООО «МСК» предлагает широкий комплекс услуг геодезических работ, таких как:',
        img: object03Big,
        imgBig: object03Big,
        p1:'• геодезические работы при строительно-монтажных работах;',
        p2:'• геодезическое обследование резервуаров;',
        p3:'• услуг по нивелировке окрайки днища резервуаров с контролем отклонения стенки резервуара от вертикали;',
        p4:'• проектирование и закладка геодезической основы;',
        p5:'• вынос в натуру осей зданий и сооружений согласно проектной документации;',
        p6:'• геодезический мониторинг зданий и сооружений в процессе эксплуатации и при строительстве.'
        
    },

    {
        title: 'Ремонт и зачистка РВС И РГС',
        description: 'ООО «МСК» предлагает услуги по зачистке резервуаров и емкостей из под нефтепродуктов. Процедура зачистки включает несколько технологических этапов, от чего непосредственно зависит чистота внутренней поверхности. Выбор технологии зачистки емкостей и резервуаров от нефтепродуктов зависит от характера загрязнений. Этапы очистки резервуаров и емкостей: ',
        img: object04Big,
        imgBig: object04Big,
        p1:'• разработка и согласование программы проведения работ;',
        p2:'• подготовка, организация подъезда к резервуару, установка оборудования, монтаж временных сборно-разборных трубопроводов;',
        p3:'• откачка резервуара, удаление остатков нефтепродукта;',
        p4:'• демонтаж люков и необходимого оборудования установленного на резервуаре;',
        p5:'• дегазация резервуара и емкостей;',
        p6:'• анализ и оценка состояния резервуара;',
        p7:'• зачистка резервуара, первичная (тщательная зачистка, мойка внутренних поверхностей), удаление отложений и финишная (финальная зачистка внутренней поверхности);',
        p8:'• оценка качества выполненных работ в составе комиссии.'
    },

    {
        title: 'Обследование зданий и сооружений',
        description: 'ООО «МСК» выполняет услуги по обследованию зданий и сооружений с целью определения их фактического состояния и соответствия требованиям нормативно-технической документации, возможности дальнейшей эксплуатации. В том числе:',
        img: object05Big,
        imgBig: object05Big,
        p1:'• визуальное обследование;',
        p2:'• определение прочности и характеристик материалов конструкций объекта неразрушающими методами контроля;',
        p3:'• мониторинг технического состояния зданий и сооружений;',
        p4:'• измерение планово-высотного положения и геометрических параметров здания и конструкций;',
        p5:'• определение параметров армирования конструкций;',
        p6:'• оценка качества болтовых соединений;',
        p7:'• поверочные расчеты несущих конструкций;',
        p8:'• консультационные услуги и разработка компенсирующих мероприятий.'
    },

    {
        title: 'Строительный контроль',
        description: 'ООО «МСК» предлагает услуги строительного контроля, проверки качества строительно-монтажных работ на предмет соответствия проектной и нормативно-технической документации. В том числе:',
        img: object06Big,
        imgBig: object06Big,
        p1:'• проверка исполнительно-технической документации на предмет соответствия фактически выполненным работам;',
        p2:'• контроль качества строительных материалов;',
        p3:'• проверка качества строительно-монтажных работ;',
        p4:'• контроль сроков выполнения строительно-монтажных работ;',
        p5:'• участие в освидетельствовании скрытых работ.'
        
    }

];

export {objects}