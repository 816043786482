

const About = () => {

    return(
        <div className="" id='home'>
            <div className="container">
            <h1 className="title-1">О компании</h1>
                <div className="cont">
                    <div className="left">
                    </div>
                    <div className="right">
                        <h2 className="title-3">ООО «МСК» имеет штат высококвалифицированных специалистов по строительно-монтажным работам, обследованию и строительному контролю, имеет положительные отзывы заказчиков в части оказанных услуг, в их числе:
                        <br/>
                        <br/> — Строительно монтажные работы;
                        <br/> — Разработка проектной документации;
                        <br/> — Геодезические услуги;
                        <br/> — Ремонт и зачистка РВС и РГС;
                        <br/> — Обследование зданий и сооружений;
                        <br/> — Строительный контроль.</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default About;