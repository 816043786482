import "./styles/main.css";

import {BrowserRouter as Router, Routes,Route} from "react-router-dom"

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Contacts from "./pages/Contacts";
import Project from "./pages/Project";




import ScrollToTop  from "./utils/scrollToTop"; 
import ProgressLine from "./components/progressLine/ProgressLine";


function App() {
  
  return (
    <div className="App">
          <Router>
                <ScrollToTop/>
                <ProgressLine/>
                <Navbar/>
                <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/services" element={<Projects/>}/>
                  <Route path="service/:id" element={<Project/>}/>
                  <Route path="contacts" element={<Contacts/>}/>
                </Routes>
                <Footer/>
          </Router> 
    </div>
  );
}

export default App;